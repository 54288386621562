<template>
  <div>
    <v-card>
      <v-row>
        <v-col cols="12">
          <v-card-title>
            PM Unmatched Data ({{ addTSBVZ(totalRecords) }})
<!--            <v-spacer></v-spacer>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-text-field-->
<!--                @keydown.enter="searchQueryData"-->
<!--                append-icon="search"-->
<!--                label="Search"-->
<!--                single-line-->
<!--                hide-details-->
<!--            ></v-text-field>-->
            <v-spacer></v-spacer>
            <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
              <i style="color: #fff" class="flaticon2-refresh-1"></i>
            </v-btn>
            <v-btn
                @click="updateMergeData"
                   color="#00004d"
                   dark
                   class="ma-3 ml-0">
              Update All
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="pagination"
              :server-items-length="totalRecords"
              :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
              :loading="loading"
              mobile-breakpoint="100"
              class="elevation-0">
            <template v-slot:item.her="{ item }">
              <div class="text-truncate">
                <span v-html="item.title"></span>
              </div>
            </template>
            <template v-slot:item.type="{ item }">
              <div class="text-truncate">
                <v-autocomplete v-model="item.property_type" outlined :items="ptFilter" item-text="hk" item-value="id" hide-details dense></v-autocomplete>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-truncate">
                <v-btn @click="deleteItem(item)" color="red" dark class="mr-2">
                  Delete
                  <v-icon dark right small>fas fa-times</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  MERGED_DATA_LIST, UPDATE_MERGED_DATA, DELETE_MERGED_DATA
} from "@/core/services/store/merge.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {DEVELOPMENTS_SIMPLE_LIST} from "@/core/services/store/developments.module";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";

export default {
  name: 'unmatched-unit-types',
  data() {
    return {
      headers: [
        {text: 'Property Type Dubizzle Title', value: 'title', name: 'title', filterable: true, sortable: true},
        {text: 'Merge With Property Type MRC Title', value: 'type', name: 'type', filterable: false, sortable: false},
        // {text: 'Action', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 50,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [50, 100, 200, 500],
      headerindex: "",
      columns: [],
      items: [],
      dialog: false,
      ptFilter: [],
      searchFormData:{}
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
  },
  mounted() {
    for (let i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.getPropertyTypes()
  },
  methods: {
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "asc" : "desc");
        this.$store.dispatch(MERGED_DATA_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = false;
          resolve({
            items,
            total
          });
        });
      });
    },
    developmentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.$store
              .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                q: e.target.value
              })
              .then(data => {
                that.developmentsFilter = data.records.developmentsList;
              });
        }
      }, 900);
    },
    projectsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.$store
              .dispatch(PROJECTS_SIMPLE_LIST, {
                q: e.target.value,
                type: 'none'
              })
              .then(data => {
                that.projectsFilter = data.records.projectsList;
              });
        }
      }, 900);
    },
    updateMergeData() {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to update?", clr: 'red', callback: function () {
          that.$store.dispatch(UPDATE_MERGED_DATA, {data: that.items})
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                });
              });
        }
      });
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete?", clr: 'red', callback: function () {
          that.$store.dispatch(DELETE_MERGED_DATA, {'id' : item.id})
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                });
              });
        }
      });
    },
    getPropertyTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'all', is_property: 1}).then((data) => {
        this.ptFilter = data.records.developmentTypesList
        if (loadNext) this.getOccupations()
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.merge.errors
    })
  }
};
</script>