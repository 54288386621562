var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" PM Unmatched Data ("+_vm._s(_vm.addTSBVZ(_vm.totalRecords))+") "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-auto ma-3 mr-1",attrs:{"color":"#00004d","dark":""},on:{"click":_vm.refreshData}},[_c('i',{staticClass:"flaticon2-refresh-1",staticStyle:{"color":"#fff"}})]),_c('v-btn',{staticClass:"ma-3 ml-0",attrs:{"color":"#00004d","dark":""},on:{"click":_vm.updateMergeData}},[_vm._v(" Update All ")])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalRecords,"footer-props":{
                    itemsPerPageOptions: _vm.rowsPerPageItems,
                    showFirstLastPage: true,
                  },"loading":_vm.loading,"mobile-breakpoint":"100"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.her",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',{domProps:{"innerHTML":_vm._s(item.title)}})])]}},{key:"item.type",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.ptFilter,"item-text":"hk","item-value":"id","hide-details":"","dense":""},model:{value:(item.property_type),callback:function ($$v) {_vm.$set(item, "property_type", $$v)},expression:"item.property_type"}})],1)]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" Delete "),_c('v-icon',{attrs:{"dark":"","right":"","small":""}},[_vm._v("fas fa-times")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }