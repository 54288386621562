<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <UnmatchedUnitTypes/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import UnmatchedUnitTypes from "@/view/pages/realstate/merging/inc/UnmatchedUnitTypes.vue";

export default {
  name: 'unmatched-data',
  components: {
    UnmatchedUnitTypes
  },
  data() {
    return {}
  },
  watch: {},
  mounted() {

  },
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.merge.errors
    }),
  }
};
</script>